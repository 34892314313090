import React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';

import { ThemeMode } from '../../context/ThemeMode';

export default function CurrencyVolumeChart({currencyVolumnData=[]}) {

    // --- ---
    const { isDarkMode } = ThemeMode();
    // --- ---

    const valueFormatter = (value) => {
        return value;
        // if (value >= 1000) {
        //     // console.log(value / 1000);
        //     return `$${(value / 1000).toFixed(2)}k`; // Format as 1k, 2k, etc.
        // } else {
        //     return `$${value}`; // Default format for values below 1000
        // }
    };

    const transformChartData = () => {
        // console.log('profitChartDataprofitChartDataprofitChartDataprofitChartData', profitChartData);
        return currencyVolumnData.map(item => ({
            Volumn: item.total_lot_size,
            Currecny: item.symbol
        }));
    };

   

    const tooltipFormatter = (data) => {
        // if (!data || !data.month || !data.Profit) return [];
       return [
            { name: 'Currency', value: data.symbol },
            { name: 'Volumn', value: data.total_lot_size},
        ];
    };

    // const chartSetting = {
    //     borderRadius: 4,
    //     height: 305,
    //     sx: {
    //         [`& .${axisClasses.directionX} .${axisClasses.label}`]: {
    //             transform: 'translateY(8px)',
    //         },
    //     },
    //     grid: {
    //         vertical: {
    //             strokeDasharray: '0', // Customize the grid lines
    //         },
    //         horizontal: {
    //             strokeDasharray: '1', // Customize the grid lines
    //         },
    //     },
    // };
    const maxTotalLotSize = currencyVolumnData.reduce((max, current) => {
        return current.total_lot_size > max ? current.total_lot_size : max;
    }, 0);
    
    const chartSetting = {
        borderRadius: 4,
        height: 305,
        series: [{ 
            dataKey: 'Volumn',
            color: `${isDarkMode ? "#3C3A4E" : "#E5E5E5"}`,
            area: true,
            showMark: true,
            // label: 'IB reward', 
            valueFormatter,
        }],
        xAxis: [{
            // label: 'Year '+new Date().getFullYear(),
            scaleType: 'band', 
            dataKey: 'Currecny' ,
        }],
        yAxis: [{
            // label: 'Account Balance',
            min: 0,
            max: maxTotalLotSize + 10,
            valueFormatter,
            ticks: Array.from({ length: Math.floor(Math.max(...currencyVolumnData.map((item) => item.total_lot_size), 100) / 5) + 1 }, (_, i) => i * 5), // Custom ticks with step of 5
        }],
        tooltip: {
            formatter: tooltipFormatter,
        },
        sx: {
            [`& .${axisClasses.directionX} .${axisClasses.label}`]: {
                transform: 'translateY(8px)',
            },
        },
        grid: {
            vertical: {
                strokeDasharray: '0', // Customize the grid lines
            },
            horizontal: {
                strokeDasharray: '1', // Customize the grid lines
            },
        },
    };
    return (
        <div className="Mycharts Mycharts2 common-pe-none">
            <BarChart
                dataset={transformChartData()}
                {...chartSetting}
                // series={[
                //     { data: [35, 54, 24, 88, 45, 95], color: '#0000', },
                //     // { data: [45, 74, 34, 34, 55, 85], color: '#3C3A4E', },
                // ]}
                // xAxis={[{ data: ['EUR/USD', 'USD/JPY', 'USD/GBP', 'USD/CNY', 'USD/CAD', 'USD/AUD'], scaleType: 'band' }]}
            />
        </div>
    );
}
