import React from 'react'

export default function ScaleOverviewItem({heading, src, min, max, percent, current }) {
  return (
  <div className="col-sm-6">
    <div className="common-box">
      <div className="scale-overview-item">
        <div className="common-box-heading"><img src={src} alt="" /> {heading}</div>

        <div className="soi-data-bx">
          <div className="soi-data-item">{min} min</div>
          <div className="soi-data-item">{max} max</div>
        </div>
        <div className="common-progress-bar">
          <div className="common-progress-inner" style={{ width: `${percent}%` }}>
            <div className="common-progress-circle"></div>
          </div>
        </div>
        <div className="soi-highlight">{current}</div>
      </div>
    </div>
  </div>
  )
}
