import React from 'react';

export default function LocationMap({className="col-12"}) {

    return (
    <div className={className}>
        <div className="common-box">
            <div className="common-box-heading">Location</div>
            <div className="location-map-bx">
            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d75348.5643130263!2d-1.9616638495992302!3d52.47894338652163!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4870942d1b417173%3A0xca81fef0aeee7998!2sBirmingham%2C%20UK!5e0!3m2!1sen!2sin!4v1731157020370!5m2!1sen!2sin"
              width="100%"
              height="100%"
              style={{ border: 0, borderRadius: "10px" }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
            </div>
        </div>
    </div>
    )}
