import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import useApi from '../../utility/apiCall'; 
import API_URL from '../../config/config';

// Images
import welcomeBg from '../../img/welcome-bg.webp'

export default function WelcomeBox() {
  const { apiCall } = useApi();
  const { state } = useAuth();
  const isInitialMount = useRef(true);
  const token = state.token || '';

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiCall(API_URL + 'get-profile', {
          user_id: state.userData.id,
          token: token,
          nopaginate: 1,
        });
        if (response.data.success == '0') {
          setFirstName(response.data.data.first_name);
          setLastName(response.data.data.last_name);
        } else {
          // console.log(response.data);
          console.error('Failed to fetch user details:', response.data.error);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (isInitialMount.current) {
        isInitialMount.current = false;
        fetchData();
    }
  }, [token]);


  return (
    <div className="welcome-bx">
      <div className="wb-data">
        <div className="wb-heading">Welcome {firstName+" "+lastName}</div>
        <div className="wb-sub-heading">Are You Ready To Buy Your First Challenge?</div>
        <div className="wb-text">We Recommend You Go Through The FAQ Section First Before Starting A Challenge</div>
        <div className="d-flex cbi-gap-10px wb-btns mt-4">
          <Link to="/new-challenge" className="common-btn cb-white-fill cwf-am-hover"><span>New Challenge</span></Link>
          <Link to="https://fundedfirm.com/trading-rules" target='_blank' className="common-btn cb-white-outline cwo-hover"><span>Trading Rules</span></Link>
        </div>
        <img className="wb-img" src={welcomeBg} alt='' />
       </div>
    </div>
  )
}
