
import React, { useState, useRef, useEffect } from "react";
import { Link } from 'react-router-dom';

import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth, getLoggedInUserData } from '../../context/AuthContext';
import FlashMessage from '../../components/FlashMessage';
import Button from 'react-bootstrap/Button';
import EmptyBox from '../../components/EmptyBox';

// React Bootstrap
import { Modal } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Components File
import AccountChart from '../ComomonChart/AccountChart';
import AccountChart2 from '../ComomonChart/AccountChart2';
import LimitCardItem from './LimitCardItem';
import ScaleOverviewItem from './ScaleOverviewItem';

// Icons & Images Files
import Icons from '../../components/icons';
import DropItem from '../../components/commonfield/DropItem';
import master from "../../img/icons/account/master.png"
import practitioner from "../../img/icons/account/practitioner.png"
import student from "../../img/icons/account/student.png"
import balanceImg from "../../img/icons/balance.svg"
import equityImg from "../../img/icons/equity.svg"
import Loaders from '../../components/Loader';
// datepaicker summary
import { format, addMonths, subMonths, startOfMonth, endOfMonth, startOfWeek, endOfWeek, addDays, isSameMonth, isSameDay, isNextDay } from 'date-fns';
import { Icon } from "@mui/material";
import { getDaysInYear } from "date-fns/fp/getDaysInYear";
import AccountStatus from "../../components/AccountStatus";
import Alert from "../../components/Alert";
import CalendarNavigation from "../../components/CalendarNavigation";
import Pagination from "../../components/Pagination";
import CloseIcon from "../../components/CloseIcon";

export default function PageAccountDetail() {

  //Auth related var

  const { apiCall } = useApi();
  const navigate = useNavigate();
  const { state, dispatch } = useAuth();
  const token = state.token || '';
  const isInitialMount = useRef(true);

  const { id } = useParams();


  const [chartType, setChartType] = useState('line');

  //Loader
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [requestSubmitError, setRequestSubmitError] = useState(false);
  const [requestSubmitErrorMsg, setRequestSubmitErrorMsg] = useState(false);
  const [requestSubmitSuccess, setRequestSubmitSuccsess] = useState(false);
  const [requestSubmitSuccessMsg, setRequestSubmitSuccessMsg] = useState(false);
  const [currentAccount, setCurrentAccount] = useState([]);
  

  // ---
  const [isCredentialsModal, setIsCredentialsModal] = useState(false);

  const [selectedOption, setSelectedOption] = useState('daily');
  const options = ['daily','week', 'month'];

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    // console.log('Selected Option:', option);
  
    // Call getProfitDataChart with the new option
    getProfitDataChart(id, option);
  };


  // trading history table var
  const [tradingHistoryList, setTradingHistoryList] = useState([]);
  const [pageTradingHistory, setPageTradingHistory] = useState(1);
  const [goPageTradingHistory, setGoPageTradingHistory] = useState(null);
  const [totalPagesTradingHistory, setTotalPagesTradingHistory] = useState(0);

  // const onChangePageTradingHistory = (pageTradingHistory) => {
  //   // console.log('pageTradingHistory', pageTradingHistory.target.value);
  //   if (pageTradingHistory.target.value == "") {
  //     pageTradingHistory.target.value = 1;
  //   }
  //   console.log('totalPagesTradingHistory', totalPagesTradingHistory);
  //   if (pageTradingHistory.target.value < parseInt(totalPagesTradingHistory)) {
  //     setPageTradingHistory(pageTradingHistory.target.value);
  //     setGoPageTradingHistory(pageTradingHistory.target.value);
  //   } else {
  //     setPageTradingHistory(totalPagesTradingHistory);
  //     setGoPageTradingHistory(totalPagesTradingHistory);
  //   }
  // }

  const onChangePageTradingHistory = (pageTradingHistory) => {
    setPageTradingHistory(pageTradingHistory);
  }

  const changePrevPageTradingHistory = () => {
    if (pageTradingHistory > 1) {
      setPageTradingHistory(pageTradingHistory - 1);
      // setGoPageTradingHistory(pageTradingHistory - 1);
      onChangePageTradingHistory(pageTradingHistory - 1)
    }

  }
  const changeNextPageTradingHistory = () => {
    if (pageTradingHistory < totalPagesTradingHistory) {
      setPageTradingHistory(pageTradingHistory + 1);
      // setGoPageTradingHistory(pageTradingHistory + 1);
      onChangePageTradingHistory(pageTradingHistory + 1)

    }
  }
  // open trading table var
  const [openTradingList, setOpenTradingList] = useState([]);
  const [pageOpenTrading, setPageOpenTrading] = useState(1);
  const [goPageOpenTrading, setGoPageOpenTrading] = useState(null);
  const [totalPagesOpenTrading, setTotalPagesOpenTrading] = useState(0);

  // const onChangePageOpenTrading = (pageOpenTrading) => {
  //   if (pageOpenTrading.target.value == "") {
  //     pageOpenTrading.target.value = 1;
  //   }
  //   if (pageOpenTrading.target.value < parseInt(totalPagesOpenTrading)) {
  //     setPageOpenTrading(pageOpenTrading.target.value);
  //     setGoPageOpenTrading(pageOpenTrading.target.value);
  //   } else {
  //     setPageOpenTrading(totalPagesOpenTrading);
  //     setGoPageOpenTrading(totalPagesOpenTrading);
  //   }
  // }

  const onChangePageOpenTrading = (pageOpenTrading) => {
    setPageOpenTrading(pageOpenTrading)
  }

  const changePrevPageOpenTrading = () => {
    if (pageOpenTrading > 1) {
      setPageOpenTrading(pageOpenTrading - 1);
      onChangePageOpenTrading(pageOpenTrading - 1);
    }
  }

  const changeNextPageOpenTrading = () => {
    if (pageOpenTrading < totalPagesOpenTrading) {
      setPageOpenTrading(pageOpenTrading + 1);
      onChangePageOpenTrading(pageOpenTrading + 1);
    }
  }



  
  // Daily summary trading table var
  const [dailySummaryTradingList, setDailySummaryTradingList] = useState([]);
  const [pageDailySummary, setPageDailySummary] = useState(1);
  // const [goPageDailySummary, setGoPageDailySummary] = useState(null);
  const [totalPagesDailySummary, setTotalPagesDailySummary] = useState(0);

  // const onChangePageDailySummary = (pageDailySummary) => {
  //   if (pageDailySummary.target.value == "") {
  //     pageDailySummary.target.value = 1;
  //   }
  //   if (pageDailySummary.target.value < parseInt(totalPagesDailySummary)) {
  //     setPageDailySummary(pageDailySummary.target.value);
  //     setGoPageDailySummary(pageDailySummary.target.value);
  //   } else {
  //     setPageDailySummary(totalPagesDailySummary);
  //     setGoPageDailySummary(totalPagesDailySummary);
  //   }
  // }

  const onChangePageDailySummary = (pageDailySummary) => {
    setPageDailySummary(pageDailySummary)
  }

  const changePrevPageDailySummary = () => {
    if (pageDailySummary > 1) {
      setPageDailySummary(pageDailySummary - 1);
      onChangePageDailySummary(pageDailySummary - 1);
    }

  }
  const changeNextPageDailySummary = () => {
    // console.log(pageDailySummary);
    // console.log(totalPagesDailySummary);
    if (pageDailySummary < totalPagesDailySummary) {
      setPageDailySummary(pageDailySummary + 1);
      onChangePageDailySummary(pageDailySummary + 1);
    }
  }

  // Other info var
  const [accountNumber, setAccountNumber] = useState('');
  const [createDate, setCreateDate] = useState('');
  const [accountStatus, setAccountStatus] = useState('');
  const [accountType, setAccountType] = useState('');
  const [accountPhase, setAccountPhase] = useState('');
  const [userWalletParentID, setUserWalletParentID] = useState('');

  const [accountBalance, setAccountBalance] = useState(0);
  const [accountSize, setAccountSize] = useState(0);
  const [accountEquity, setAccountEquity] = useState(0);
  const [email, setEmail] = useState('');
  const [masterPassword, setMasterPassword] = useState('');
  const [startTradePeriod, setStartTradePeriod] = useState('');
  const [endTradePeriod, setEndTradePeriod] = useState('');
  const [avgWin, setAvgWin] = useState(0);
  const [avgLoss, setAvgLoss] = useState(0);



  // analysis var
  const [numberOfDays, setNumberOfDays] = useState(0);
  const [totalTradesTaken, setTotalTradesTaken] = useState(0);
  const [avgTradesPerDay, setAvgTradesPerDay] = useState(0);
  const [totalLotsUsed, setTotalLotsUsed] = useState(0);
  const [avgLotsUsed, setAvgLotsUsed] = useState(0);
  const [biggestWin, setBiggestWin] = useState(0);
  const [biggestLoss, setbiggestLoss] = useState(0);
  const [positiveDay, setPositiveDay] = useState(0);
  const [negativeDay, setNegativeDay] = useState(0);


  const [dailyMaxLossLimit, setDailyMaxLossLimit] = useState(0);
  const [maxOverallLossLimit, setMaxOverallLossLimit] = useState(0);
  const [profitTarget, setProfileTarget] = useState(0);
  const [dailyMaxThreadShold, setDailyMaxThreadShold] = useState(0);
  const [maxOverallLossThreadShold, setMaxOverallLossThreadShold] = useState(0);
  const [makeOverAllLossPercentage, setMakeOverAllLossPercentage] = useState(0);
  const [makeDailyLossPercentage, setMakeDailyLossPercentage] = useState(0);
  const [makeOverAllProfitPercentage, setMakeOverAllProfitPercentage] = useState(0);
  const [maxTradingDays, setMaxTradingDays] = useState(0);
  const [countMaxTradingDaysLeft, setCountMaxTradingDaysLeft] = useState(0);
  const [countMaxTradingDaysLeftPercentage, setCountMaxTradingDaysLeftPercentage] = useState(0);
  const [minBalance, setMinBalance] = useState(0);
  const [maxBalance, setMaxBalance] = useState(0);
  const [minEquity, setMinEquity] = useState(0);
  const [maxEquity, setMaxEquity] = useState(0);
  const [remainDailyLoss, setRemainDailyLoss] = useState(0);
  const [remainMaxLoss, setRemainMaxLoss] = useState(0);
  const [remainMaxProfit, setRemainMaxProfit] = useState(0);
  const [totalLoss, setTotalLoss] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);
  const [winRatio, setWinRatio] = useState(0);
  const [checkRealAccountIsCreated, setCheckRealAccountIsCreated] = useState(0);
  const [checkPassedAccountIsCreated, setCheckPassedAccountIsCreated] = useState(0);
  const [checkRealAccountIsApproved, setCheckRealAccountIsApproved] = useState(0);
  const [checkRealAccountIsRejected, setCheckRealAccountIsRejected] = useState(0);
  const [checkPassedAccountIsApproved, setCheckPassedAccountIsApproved] = useState(0);
  const [checkPassedAccountIsRejected, setCheckPassedAccountIsRejected] = useState(0);
  const [passedAccountRequest, setPassedAccountRequest] = useState(false);

  const [applyForRealAccount, setApplyForRealAccount] = useState(0);

  const [filterValue, setFilterValue] = useState('daily');


  const [showCustomFilter, setShowCustomFilter] = useState(false);


  const [profitChartData, setProfitChartData] = useState([]);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [showCertificate, setShowCertificate] = useState(0);

  const handleButtonFilterChartClick = (event) => {
    const buttons = document.querySelectorAll('.dashbord-filter button');
    buttons.forEach((button) => button.classList.remove('active'));
    event.target.classList.add('active');

    // Show custom filter only if "Custom" button is clicked
    if (event.target.value === 'custom') {
      // console.log(startDate);
      // console.log(endDate);
      setFilterValue(event.target.value);
      setShowCustomFilter(true);
    } else {
      setFilterValue(event.target.value);
      setShowCustomFilter(false);

    }
  };

  function calculateTimeLeft() {
    const now = new Date();
    const endOfDay = new Date();
    endOfDay.setHours(23, 59, 59, 0); // 11:59:59 PM

    let timeDiff = endOfDay.getTime() - now.getTime();

    if (timeDiff < 0) {
      timeDiff = 0; // If end of day has passed, set to zero
    }

    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

    return {
      hours: hours.toString().padStart(2, '0'),
      minutes: minutes.toString().padStart(2, '0'),
      seconds: seconds.toString().padStart(2, '0')
    };
  }

  function calculateTimeLeftLatest() {
    const now = new Date();
    const nextDay = new Date();
    
    // Set next day's 3:30 AM
    nextDay.setDate(now.getDate() + 1);
    nextDay.setHours(3, 30, 0, 0); // 3:30:00 AM

    let timeDiff = nextDay.getTime() - now.getTime();

    if (timeDiff < 0) {
        timeDiff = 0; // If for some reason the time has passed, set to zero
    }

    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

    return {
        hours: hours.toString().padStart(2, '0'),
        minutes: minutes.toString().padStart(2, '0'),
        seconds: seconds.toString().padStart(2, '0')
    };
}

  useEffect(() => {
    const timer = setInterval(() => {
      // console.log('accountNumberaccountNumberaccountNumber',state.userData.email);
      if (state.userData.email == "harsh@pmcommu.com") {
        setTimeLeft(calculateTimeLeftLatest());
      } else {
        setTimeLeft(calculateTimeLeft());
      }
      
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const copyClick = (value) => {
    setRequestSubmitSuccsess(false);
    setRequestSubmitError(false);
    navigator.clipboard.writeText(value)
      .then(() => {
        setRequestSubmitSuccsess(true);
        setRequestSubmitSuccessMsg('Text copied to clipboard');
        // console.log('Text copied to clipboard');
      })
      .catch((error) => {
        setRequestSubmitError(true)
        setRequestSubmitErrorMsg('Error copying text')
        // console.error('Error copying text: ', error);
      });
  };

  const anotherFormatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    };

    return date.toLocaleString('en-US', options);
  };


  const handleCredentialsModalClose = () => {
    setIsCredentialsModal(false);
  };


  const formatStatus = (status) => {
    if (!status) return ''; // Handle case where status might be undefined or null
    if(status == 'passed'){
      status = 'Profit target achieved';
    }
    const formattedStatus = status.replace(/_/g, ' '); // Replace underscores with spaces
    return formattedStatus.charAt(0).toUpperCase() + formattedStatus.slice(1); // Capitalize first letter
  };
  const handleCredentialsModalShow = async (challengeID) => {
    try {
      const response = await apiCall(API_URL + 'get-challenge-details', {
        user_id: state.userData.id,
        id: challengeID,
        token: token
      });
      if (response.data.success == '0') {
        // console.log(response.data);
        setEmail(response.data.data.email);
        setAccountNumber(response.data.data.account_number);
        setMasterPassword(response.data.data.master_password);
        setIsCredentialsModal(true);
        // setChallengeDetails(response.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setIsCredentialsModal(true);
  }
  // ---

  // ---
  const [isSnapshotModal, setIsSnapshotModal] = useState(false);
  const [snapShotTitle, setSnapShotTitle] = useState(false);

  const handleSnapshotModalClose = () => {
    setIsSnapshotModal(false);
  };
  // ---


  // ---
  const [isAnalysisModal, setIsAnalysisModal] = useState(false);

  const handleAnalysisModalClose = () => {
    setIsAnalysisModal(false);
  };

  const handleAnalysisModalShow = async (title) => {
    // setIsAnalysisModal(true);
    try {
      const response = await apiCall(API_URL + 'get-analysis-data', {
        user_id: state.userData.id,
        userWalletID: id,
        token: token
      });
      if (response.data.success == '0') {
        setSnapShotTitle(title);
        setNumberOfDays(response.data.data.numberOfDays)
        setTotalTradesTaken(response.data.data.totalTradesTaken)
        setAvgTradesPerDay(response.data.data.averageTradesPerDay)
        setTotalLotsUsed(response.data.data.totalLotsUsed)
        setAvgLotsUsed(response.data.data.averageLotsUsed)
        setBiggestWin(response.data.data.biggestWin)
        setbiggestLoss(response.data.data.biggestLoss)
        setPositiveDay(response.data.data.positiveDays)
        setNegativeDay(response.data.data.negativeDays)
        setIsAnalysisModal(true);
        // setChallengeDetails(response.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setIsAnalysisModal(true);
  }


  // ---

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [changeMonth, setChangeMonth] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [changeSelectedDate, setChangeSelectedDate] = useState();

  const [dataForCalender, setDataForCalender] = useState(null);


  const [tradeTakenSelectedDay, setTradeTakenSelectedDay] = useState();
  const [endingBalanceSelectedDay, setEndingBalanceSelectedDay] = useState();
  const [usedLotsSelectedDay, setUsedLotsSelectedDay] = useState();
  const [amountSelectedDay, setAmountSelectedDay] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const loggedInUser = getLoggedInUserData();
      const isUserLoggedIn = !!loggedInUser;

      if (isUserLoggedIn) {
        if (loggedInUser.id && token) {
          try {
            // getIbRequestStatus();
            // const profitChart = await getProfitDataChart();
            // const tradeHistory = await getTradingHistoryList();
            // const openTrade = await getOpenTradingList();
            // const dailySummary = await getDailySummaryTradingList();
            // const calenderSummary = await getDataForAccountDetailsCalender();
            const tradeDashboard = await getTradDashboardDetails();
            const tradeDashboardreal = await getRealTimeDataForAccountDetail();
            setDataLoaded(true);
          } catch (error) {
            navigate('/login');
            console.error('Failed to fetch ib data:', error);
          }
        }
      }
    };

    if (isInitialMount.current) {
      isInitialMount.current = false;
      fetchData();
    }
  }, [token]);

  
  
 
  
  const [sortFieldone, setSortFieldone] = useState('open_date'); // Default sort by date
  const [sortDirectionone, setSortDirectionone] = useState('asc'); // Default direction

  

  const getTradingHistoryList = async () => {
    try {
      // setIsLoading(true);
      // console.log({id});
      const response = await apiCall(API_URL + 'get-trading-history-list', {
        user_id: state.userData.id,
        token: token,
        page: pageTradingHistory,
        per_page: 5,
        user_wallet_id: id,
        sort_field: sortFieldone, // Pass sort field
        sort_direction: sortDirectionone, // Pass sort direction
      });
      if (response.data.success == '0') {
        // console.log('trading list history',response.data.data.last_page);
        setTradingHistoryList(response.data.data.data)
        setTotalPagesTradingHistory(response.data.data.last_page)
      }
      // setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const handleSortone = (field) => {
    if (sortFieldone === field) {
        // Toggle direction when the same field is clicked
        setSortDirectionone(sortDirectionone === 'asc' ? 'desc' : 'asc');
    } else {
        // Set the field to be sorted by, default to ascending
        setSortFieldone(field);
        setSortDirectionone('asc');
    }
  };
  useEffect(() => {
    getTradingHistoryList();
  }, [sortFieldone, sortDirectionone, pageTradingHistory]);
  


  const getDataForAccountDetailsCalender = async () => {
    try {
      // setIsLoading(true);
      // console.log({id});
      const response = await apiCall(API_URL + 'get-data-for-account-details-calender', {
        user_id: state.userData.id,
        token: token,
        user_wallet_id: id,
        year: format(currentMonth, 'yyyy'),
        month: format(currentMonth, 'M')
      });
      if (response.data.success == '0') {
        // console.log('datatatatatacashgvkasfgasukdjfvashukfbukdhvbdla',response.data);
        setDataForCalender(response.data)
        // setTotalPagesTradingHistory(response.data.data.last_page)
      }
      // setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const getDataForAccountDetailsDateWise = async () => {
    try {
      // setIsLoading(true);
      // console.log({id});
      // console.log('before', selectedDate);
      const response = await apiCall(API_URL + 'get-data-for-account-details-date-wise', {
        user_id: state.userData.id,
        token: token,
        user_wallet_id: id,
        select_date: selectedDate
      });
      if (response.data.success == '0') {
        // console.log('abc', response.data.data.profit - response.data.data.loss);
        const netAmount = response.data.data.profit - response.data.data.loss;
        setTradeTakenSelectedDay(response.data.data.count);
        setEndingBalanceSelectedDay(response.data.data.ending_balance);
        setUsedLotsSelectedDay(response.data.data.lots);
        setAmountSelectedDay(netAmount);
      }
      // setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };



  const getProfitDataChart = async (userWalletId, filterOption) => {
    try {
      // setIsLoading(true);
      // console.log({id});
      const response = await apiCall(API_URL + 'get-trade-data-for-chart', {
        user_id: state.userData.id,
        token: token,
        user_wallet_id: id,
        filter_apply_value: filterOption,
      });
      if (response.data.success == '0') {
        // console.log('trading list history', response.data.data.profitData);
        setProfitChartData(response.data.data.profitData);
        // setProfitChartData(response.data.data.data)
      }
      // setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    getProfitDataChart(id,filterValue);
  }, [filterValue]);

  // useEffect(() => {
  //   getProfitDataChart();
  // },[]);

  useEffect(() => {
    if (parseInt(pageTradingHistory) > 0) {
      getTradingHistoryList();
    }
  }, [pageTradingHistory]);

  // useEffect(() => {
  //   // if(parseInt(pageOpenTrading) > 0){
  //   // }
  //   getOpenTradingList();
  // }, [pageOpenTrading]);

  useEffect(() => {
    if (parseInt(pageDailySummary) > 0) {
      getDailySummaryTradingList();
    }
  }, [pageDailySummary]);

  const getTradDashboardDetails = async () => {
    try {
      setIsLoading(true);
      // console.log({id});
      const response = await apiCall(API_URL + 'get-trade-dashboard-details', {
        user_id: state.userData.id,
        token: token,
        userWalletID: id
      });
      if (response.data.success == '0') {
        //console.log(response.data.data.challengeInfo);
        const dashboardDetails = response.data.data;
        setAccountNumber(dashboardDetails.challengeInfo.account_number);
        setCreateDate(dashboardDetails.challengeInfo.created_at);
        setAccountStatus(dashboardDetails.challengeInfo.account_status);
        setAccountType(dashboardDetails.challengeInfo.account_type.step);
        setAccountPhase(dashboardDetails.challengeInfo.account_type.name);
        setUserWalletParentID(dashboardDetails.challengeInfo.parent_user_wallet_id ? dashboardDetails.challengeInfo.parent_user_wallet_id : '');
        setAccountSize(dashboardDetails.challengeInfo.account_size.limit);
        setAccountBalance(dashboardDetails.challengeInfo.available_balance);
        setAccountEquity(dashboardDetails.challengeInfo.equity);
        setStartTradePeriod(dashboardDetails.startPeriodDate.created_at);
        setEndTradePeriod(dashboardDetails.endPeriodDate.created_at);
        setAvgWin(dashboardDetails.winAverage);
        setAvgLoss(dashboardDetails.lossAverage);
        setProfileTarget(dashboardDetails.profitTarget)
        setMakeOverAllProfitPercentage(dashboardDetails.makeOverAllProfitPercentage)
        setMaxTradingDays(dashboardDetails.maxTradingDays)
        setCountMaxTradingDaysLeft(dashboardDetails.countMaxTradingDaysLeft)
        setCountMaxTradingDaysLeftPercentage(dashboardDetails.countMaxTradingDaysLeftPercentage)
        setMinBalance(dashboardDetails.minBalance);
        setMaxBalance(dashboardDetails.maxBalance);
        setMinEquity(dashboardDetails.minEquity);
        setMaxEquity(dashboardDetails.maxEquity);
        setRemainDailyLoss(dashboardDetails.remainDailyLoss);
        setRemainMaxLoss(dashboardDetails.remainMaxLoss);
        setRemainMaxProfit(dashboardDetails.remainMaxProfit);
        setTotalLoss(dashboardDetails.totalLoss);
        setTotalProfit(dashboardDetails.totalProfit);
        setWinRatio(dashboardDetails.winRatio);
        setPassedAccountRequest(dashboardDetails.passedAccountRequest);
        setCheckRealAccountIsCreated(dashboardDetails.checkRealAccountIsCreated);
        setCheckRealAccountIsRejected(dashboardDetails.checkRealAccountIsRejected);
        setCheckRealAccountIsApproved(dashboardDetails.checkRealAccountIsApproved);
        setCheckPassedAccountIsCreated(dashboardDetails.checkPassedAccountIsCreated);
        setCheckPassedAccountIsRejected(dashboardDetails.checkPassedAccountIsRejected);
        setCheckPassedAccountIsApproved(dashboardDetails.checkPassedAccountIsApproved);
        setShowCertificate(dashboardDetails.showCertificate);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getOpenTradingList = async () => {
    try {
      // setIsLoading(true);
      // console.log({id});
      const response = await apiCall(API_URL + 'get-open-positions-list', {
        user_id: state.userData.id,
        token: token,
        page: pageOpenTrading,
        per_page: 5,
        user_wallet_id: id
      });
      if (response.data.success == '0') {
        setOpenTradingList(response.data.data.data)
        setTotalPagesOpenTrading(response.data.data.last_page)
      }
      // setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };



  const [sortField, setSortField] = useState('close_date'); // Default sort by date
const [sortDirection, setSortDirection] = useState('asc'); // Default direction

const getDailySummaryTradingList = async () => {
  try {
      const response = await apiCall(API_URL + 'get-daily-summary-trade-list', {
          user_id: state.userData.id,
          token: token,
          page: pageDailySummary,
          per_page: 5,
          user_wallet_id: id,
          sort_field: sortField, // Pass sort field
          sort_direction: sortDirection, // Pass sort direction
          
      });

      if (response.data.success == '0') {
          setDailySummaryTradingList(response.data.data.data);
          setTotalPagesDailySummary(response.data.data.last_page);
      }
  } catch (error) {
      console.error('Error fetching data:', error);
  }
};


const handleSort = (field) => {
  if (sortField === field) {
      // Toggle direction when the same field is clicked
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
  } else {
      // Set the field to be sorted by, default to ascending
      setSortField(field);
      setSortDirection('asc');
  }
};


// Trigger the fetch when sorting changes
useEffect(() => {
  getDailySummaryTradingList();
}, [sortField, sortDirection, pageDailySummary]);



  useEffect(() => {
    const interval = setInterval(() => {
      if (accountStatus !== 'disabled') {
        getRealTimeDataForAccountDetail();
      }
    }, 15000);

    if (accountStatus == 'disabled') {
      clearInterval(interval);
    }

    return () => clearInterval(interval); // Cleanup on unmount
  }, [token, id, accountStatus]);

  const getRealTimeDataForAccountDetail = async () => {
    try {
      // setIsLoading(true);
      // console.log({id});
      const response = await apiCall(API_URL + 'get-real-time-data-for-account-details', {
        user_id: state.userData.id,
        token: token,
        user_wallet_id: id
      });
      if (response.data.success == '0') {
        // console.log('real data get',response.data.data)
        setAccountBalance(response.data.data.balance)
        setAccountEquity(response.data.data.equity)
        setRemainDailyLoss(response.data.data.remainDailyLoss)
        setRemainMaxLoss(response.data.data.remainOverAllLoss)
        setDailyMaxLossLimit(response.data.data.dailyLoss)
        setMaxOverallLossLimit(response.data.data.overAllLoss)
        setMakeOverAllLossPercentage(response.data.data.totalOverAllLossPercentage)
        setMakeDailyLossPercentage(response.data.data.totalDailyLossPercentage)
        setDailyMaxThreadShold(response.data.data.dailyThreadshold)
        setMaxOverallLossThreadShold(response.data.data.overAllThreadshold)
        setAccountStatus(response.data.data.accountStatus);
      }
      // setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const sendRealAccountRequest = async () => {
    try {
      setIsLoading(true);
      // console.log({id});
      setRequestSubmitSuccsess(false);

      const response = await apiCall(API_URL + 'send-real-account-request', {
        user_id: state.userData.id,
        token: token,
        user_wallet_id: id
      });
      if (response.data.success == '0') {
        setCheckRealAccountIsCreated(1);
        setRequestSubmitSuccsess(true);
        setRequestSubmitSuccessMsg('Your real account request has been sent');
        setIsLoading(false);
      } else {
        setIsLoading(false);

      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const sendPassedAccountRequest = async () => {
    try {
      setIsLoading(true);
      // console.log({id});
      setRequestSubmitSuccsess(false);

      const response = await apiCall(API_URL + 'send-passed-account-request', {
        user_id: state.userData.id,
        token: token,
        user_wallet_id: id
      });
      if (response.data.success == '0') {
        setCheckPassedAccountIsCreated(1);
        setRequestSubmitSuccsess(true);
        setRequestSubmitSuccessMsg('Your passed account request has been sent');
        setIsLoading(false);
      } else {
        setIsLoading(false);

      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSendRealAccountRequest = () => {
    sendRealAccountRequest();
  }

  const handleSendPassedAccountRequest = () => {
    sendPassedAccountRequest();
  }

  useEffect(() => {
    if (applyForRealAccount == 1) {
      getTradDashboardDetails();
    }
  }, [applyForRealAccount]);

  // useEffect(() => {
  //   if (applyForRealAccount == 1) {
  //     getTradDashboardDetails();
  //   }
  // }, [applyForRealAccount]);


  // Calander Header section
  const calenderHeader = () => {
    return (
      <CalendarNavigation
        className="calender-header"
        prev={prevMonth}
        next={nextMonth}
        date={`${format(currentMonth, 'MMM')} ${format(currentMonth, 'yyyy')}`}
      />
    );
  };

  // Calander Days section 
  const calanderWeeks = () => {
    const days = [];
    const date = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="cw-item" key={i}>{date[i]}</div>
      );
    }
    return <div className="calander-weeks">{days}</div>;
  };

  // Calander Days section
  const calanderCells = () => {
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);
    const rows = [];

    let days = [];
    let day = startDate;
    let formattedDate = '';
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, 'd');
        const dateString = format(day, 'yyyy-MM-dd');
        const dayData = dataForCalender.data[dateString] || { profit: 0, loss: 0 };
        const netAmount = (dayData.profit - dayData.loss) != 0 ? (dayData.profit - dayData.loss).toFixed(2) : 0;
        const eventClass = netAmount == 0 ? '' : netAmount < 0 ? 'cd-i-red' : 'cd-i-green';
        const eventSign = netAmount == 0 ? '$' : netAmount < 0 ? '-$' : '+$';
        days.push(
          <div key={day} className={`cd-item ${!isSameMonth(day, monthStart) ? 'cd-i-disabled' : ''} ${isSameDay(day, selectedDate) ? 'cd-i-seleted' : ''} ${eventClass}`} onClick={netAmount != 0 ? () => onDateClick(dateString) : undefined}>
            <span className="cd-i-date">{formattedDate}</span>
            <span className="cd-i-event">{`${eventSign + Math.abs(netAmount)}`}</span>
          </div>
        );
        day = addDays(day, 1);
      }
      rows.push(
        <div className="calander-days" key={day}>{days}</div>
      );
      days = [];
    }

    return rows;
  };

  const nextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
    setChangeMonth(addMonths(currentMonth, 1));
  };

  const prevMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
    setChangeMonth(subMonths(currentMonth, 1));
  };

  const onDateClick = (day) => {
    console.log('selected day', day);
    setSelectedDate(day);
    setChangeSelectedDate(day);
  };

  useEffect(() => {
    getDataForAccountDetailsDateWise();
  }, [changeSelectedDate]);


  useEffect(() => {
    getDataForAccountDetailsCalender();
  }, [changeMonth]);

  const downloadCertificate = async () => {
    try {
      // setIsLoading(true);
      // console.log({id});
      const response = await apiCall(API_URL + 'download-pdf', {
        user_id: state.userData.id,
        token: token,
        type: "account_details",
        user_wallet_id: id
      });
      if (response.data.success == '0') {
        // window.open(response.data.data.download_link);

        //   console.log(response);
        const link = document.createElement('a');
        link.href = response.data.data.download_link;
        link.setAttribute('target', '_blank'); // Open in a new tab

        // link.setAttribute('download', 'passed_certificate.pdf'); // Set default filename (optional)
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

      }
      // setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  return (
    <>
      {!dataLoaded && <Loaders />}

      {dataLoaded &&
        <div className="container-lg cl-custome3">
          <div className="row row-gap-4">


            {/* --- Top Section Start --- */}
            <div className="col-12">
              <div className="account-info-bx">
                <div className="aib-inner-bx">
                  <div className="aib-i-img"><img src={accountStatus == 'real' ? practitioner : student} alt="" /></div>
                  <div className="aib-i-data">
                    <div className="aib-i-name">MT5 {accountNumber}
                      <AccountStatus
                        className={(accountStatus == 'not_passed') ? 'status-red2' : (accountStatus == 'refunded' ? 'status-yellow2' : (accountStatus == 'disabled' ? 'status-yellow2' : (accountStatus == 'passed' ? 'status-green2' : 'status-green2')))}
                        statusText={formatStatus(accountStatus)}
                      />
                    </div>
                    <div className="aib-i-created">Created At: {anotherFormatDate(createDate)}</div>
                  </div>
                </div>
                <div className="d-flex justify-content-end flex-wrap cbi-gap-10px">
                  <div className="common-btn cb-wb1-outline cwo-hover" onClick={() => handleAnalysisModalShow('Account analysis')}><span><Icons.BarSvg />Analysis</span></div>
                  <div className="common-btn cb-white-fill cwf-am-hover" onClick={() => handleCredentialsModalShow(id)}><span><Icons.Key2Svg />Credentials</span></div>
                  {(accountStatus == 'passed' && showCertificate == 1) &&
                    <div className="common-btn cb-white-fill cwf-am-hover" onClick={() => downloadCertificate()}><span><Icons.AcademicCapSvg />Certificate</span></div>
                  }
                </div>
              </div>
            </div>


            {/* --- Passed or 2 step alert message --- */}
            {((accountStatus == 'passed' && userWalletParentID != '' && accountType == "2 step" && checkRealAccountIsRejected == 0) || (accountStatus == 'passed' && accountType == "1 step" && checkRealAccountIsRejected == 0) || (accountStatus == 'passed' && accountType == "HFT" && checkRealAccountIsRejected == 0)) &&
            <div className="col-12" id="RealAccountApply">
              <Alert
                className="status-green3 alert-wbtn"
                icon="NotificationsSvg"
                heading="Congratulations "
                subHeading="Challenge Passed"
                text={checkRealAccountIsCreated === 0 ? ("Congratulations your profit target has been achieved!  Now you can proceed to apply for real account.") : checkRealAccountIsApproved === 0 ? ("Your real account request is under review.") : ("Your real account details have been sent to your email.")}
                btnText={checkRealAccountIsCreated === 0 ? "Apply Here" : undefined}
                btnClick={checkRealAccountIsCreated === 0 ? handleSendRealAccountRequest : undefined}
              />
            </div>
            }

            {((accountStatus == 'not_passed' && passedAccountRequest && accountType == "2 step" && checkPassedAccountIsRejected == 0)) &&
              <div className="col-12" id="RealAccountApply">
                <Alert
                  className="status-green3 alert-wbtn"
                  icon="NotificationsSvg"
                  heading="Congratulations "
                  subHeading="Congratulations profit target achieved"
                  text={checkPassedAccountIsCreated === 0 ? ("Congratulations your profit target has been achieved!  Now you can proceed to apply for phase 2 account.") : checkPassedAccountIsApproved === 0 ? ("Your account request is under review") : ("Your phase 2 account details have been sent to your email.")}
                  btnText={checkPassedAccountIsCreated === 0 ? "Apply Here" : undefined}
                  btnClick={checkPassedAccountIsCreated === 0 ? handleSendPassedAccountRequest : undefined}
                />
              </div>
            }

            {/* --- Disabled alert message --- */}
            {(accountStatus == 'disabled') &&
              <div className="col-12">
                <Alert
                  className="status-red2 alert-style2 alert-wbtn"
                  icon="NotificationsSvg"
                  heading="Violation Alert! "
                  subHeading="Maximum Loss Exceeded!"
                  btnText="View Snapshot"
                  btnClick={() => handleAnalysisModalShow('Loss')}
                />
              </div>
            }

            {/* --- Next phase alert message --- */}
            <div className="col-12 d-none">
              <Alert
                className="status-yellow2"
                icon="NotificationsSvg"
                heading="Next Phase"
                text="Congratulations, you will receive your next phase account shortly. This is an automatic process"
              />
            </div>


            {/* --- Account Balance section --- */}
            <div className="col-12">
              <div className="account-detail-row">
                <div className="adr-right">
                  <div className="common-box">
                    <div className="common-box-heading">Overview</div>

                    <div className="account-basic-info">
                      <div className="abi-item">
                        <div className="abi-label"><Icons.PresentationChartBarSvg />Platform</div>
                        <div className="abi-data"><span>Metatrader 5</span></div>
                      </div>
                      <div className="abi-item">
                        <div className="abi-label"><Icons.UserCircleSvg />Account Type</div>
                        <div className="abi-data"><span>{accountType}</span></div>
                      </div>
                      <div className="abi-item">
                        <div className="abi-label"><Icons.TradeSvg />Phase</div>
                        <div className="abi-data">{accountPhase}</div>
                      </div>
                      <div className="abi-item">
                        <div className="abi-label"><Icons.EwalletsSvg />Account size</div>
                        <div className="abi-data abi-highlight">${accountSize}.00</div>
                      </div>
                      <div className="abi-item">
                        <div className="abi-label"><Icons.CalendarSvg />Start trade period:</div>
                        <div className="abi-data">{anotherFormatDate(startTradePeriod) != 'Invalid Date' ? anotherFormatDate(startTradePeriod) : ''}</div>
                      </div>
                      <div className="abi-item">
                        <div className="abi-label"><Icons.CalendarSvg /> End trade period:</div>
                        <div className="abi-data">{anotherFormatDate(endTradePeriod) != 'Invalid Date' ? anotherFormatDate(endTradePeriod) : ''}</div>
                      </div>
                    </div>

                    <Alert
                      className="status-green3 mt-4"
                      icon="CurrencyDollarSvg"
                      heading="Trading objectives"
                      text="Please note: Hitting either the daily loss limit or the maximum loss limit will result in a permanent account breach."
                    />

                    <div className="mt-3">
                      <div className="row row-gap-3">
                        <LimitCardItem
                          className="col-12 order-1"
                          chartType="line"
                          heading="Min Trading Days"
                          leftAmount={countMaxTradingDaysLeft + " Day(s)"}
                          leftText="Left"
                          percent={countMaxTradingDaysLeftPercentage != 0 ? countMaxTradingDaysLeftPercentage.toFixed(2) : 0}
                          minTradingDay={maxTradingDays}
                        />

                        <LimitCardItem
                          className="col-md-12 col-sm-7 order-2"
                          chartType="halfarc"
                          heading="Daily Loss Limit"
                          lcTime={timeLeft.hours + ":" + timeLeft.minutes + ":" + timeLeft.seconds}
                          leftAmount={"$" + (remainDailyLoss > 0 ? remainDailyLoss.toFixed(2) : 0)}
                          leftText="Left"
                          percent={makeDailyLossPercentage != 0 ? makeDailyLossPercentage.toFixed(2) : 0}
                          maxDailyLoss={"$" + (dailyMaxLossLimit > 0 ? dailyMaxLossLimit.toFixed(2) : 0)}
                          balance={"$" + accountBalance}
                          threshold={"$" + (dailyMaxThreadShold > 0 ? dailyMaxThreadShold.toFixed(2) : 0)}
                        />

                        <LimitCardItem
                          className="col-12 order-md-3 order-sm-4 order-3"
                          chartType="line"
                          heading="Max Loss Limit"
                          leftAmount={"$" + (remainMaxLoss > 0 ? remainMaxLoss.toFixed(2) : 0)}
                          leftText="Left"
                          percent={makeOverAllLossPercentage != 0 ? makeOverAllLossPercentage.toFixed(2) : 0}
                          maxLoss={"$" + (maxOverallLossLimit > 0 ? maxOverallLossLimit.toFixed(2) : 0)}
                          threshold={"$" + (maxOverallLossThreadShold > 0 ? maxOverallLossThreadShold.toFixed(2) : 0)}
                        />
                        {
                          (accountStatus != 'real') &&

                          <LimitCardItem
                            className="col-md-12 col-sm-5 order-md-4 order-sm-3 order-4"
                            heading="Profit Target"
                            leftAmount={"$" + (remainMaxProfit > 0 ? remainMaxProfit.toFixed(2) : 0)}
                            leftText="Left"
                            percent={makeOverAllProfitPercentage != 0 ? makeOverAllProfitPercentage.toFixed(2) : makeOverAllProfitPercentage}
                            profitTarget={"$" + (profitTarget > 0 ? profitTarget.toFixed(2) : 0)}
                          />
                        }

                      </div>
                    </div>

                  </div>
                </div>

                <div className="adr-left">

                  <div className="common-box overflowhidden">
                    <div className="common-box-heading">Account Overview</div>
                    <div className="account-overview-filter">
                      <div className={`aof-chart-filter ${chartType === 'line' ? 'lineChart' : 'barChart'}`}>
                        <div className={`acf-item ${chartType === 'line' ? 'active' : ''}`} onClick={() => setChartType('line')}>Line Chart</div>
                        <div className={`acf-item ${chartType === 'bar' ? 'active' : ''}`} onClick={() => setChartType('bar')}>Bar Chart</div>
                      </div>
                      <DropItem 
                        className='ci-tf-capitalize'
                        options={options}
                        selectedOption={selectedOption}
                        onChange={handleOptionChange}
                      />
                    </div>

                    <div className="aoi-outer">
                      <div className="account-overview-item">
                        <div className="aoi-label">Account balance</div>
                        <div className="aoi-data">${accountBalance}</div>
                      </div>

                      <div className="account-overview-item">
                        <div className="aoi-label">Equity:</div>
                        <div className="aoi-data">${accountEquity}</div>
                      </div>

                      <div className="account-overview-item">
                        <div className="aoi-label">Profit:</div>
                        <div className="aoi-data status-aqua">{totalProfit != 0 && accountBalance > 0 ? parseFloat((totalProfit / accountSize) * 100).toFixed(2) : 0}%</div>
                      </div>
                    </div>

                    {/* <AccountChart profitChartData={profitChartData} chartType={chartType} filterValue={selectedOption}  /> */}
                    <AccountChart2 profitChartData={profitChartData} chartType={chartType} filterValue={selectedOption}/>
                    <div className="common-section-separator mt-0"></div>

                    <div className="aoi-outer aoi-fix-width">
                      <div className="account-overview-item">
                        <div className="aoi-label">Average win</div>
                        <div className="aoi-data">${avgWin > 0 ? parseFloat(avgWin).toFixed(2) : 0}<span className='aoi-status-green'>+10.9%</span></div>
                      </div>

                      <div className="account-overview-item">
                        <div className="aoi-label">Average loss</div>
                        <div className="aoi-data">${avgLoss > 0 ? parseFloat(avgLoss).toFixed(2) : 0}<span className='aoi-status-red'>-3.9%</span></div>
                      </div>

                      <div className="account-overview-item">
                        <div className="aoi-label">Win ratio</div>
                        <div className="aoi-data">{winRatio > 0 ? winRatio.toFixed(2) : 0}%</div>
                      </div>

                      <div className="account-overview-item">
                        <div className="aoi-label">Profit Factor</div>
                        <div className="aoi-data">{totalProfit > 0 && totalLoss != 0 ? (totalProfit / totalLoss).toFixed(2) : 0}</div>
                      </div>
                    </div>
                  </div>

                  <div className="common-box mt-4">
                    <div className="common-box-heading">Calender</div>
                    <div className="calender-bx">
                      {calenderHeader()}
                      {calanderWeeks()}
                      {dataForCalender && calanderCells !== null && calanderCells()}
                    </div>
                    <div className="common-box-heading cbh-16small status-aqua"><Icons.CalendarSvg />{format(selectedDate, 'EEE dd MMM')}</div>
                    
                    <div className="aoi-outer aoi-fix-width">
                      <div className="account-overview-item">
                        <div className="aoi-label">Ending balance</div>
                        <div className="aoi-data">${endingBalanceSelectedDay}</div>
                      </div>

                      <div className="account-overview-item">
                        <div className="aoi-label">Trades taken</div>
                        <div className="aoi-data">{tradeTakenSelectedDay}</div>
                      </div>

                      <div className="account-overview-item">
                        <div className="aoi-label">Lots used</div>
                        <div className="aoi-data">{usedLotsSelectedDay}</div>
                      </div>

                      <div className="account-overview-item">
                        <div className="aoi-label">Profit</div>
                        <div className={`aoi-data ${amountSelectedDay === 0 ? "" : amountSelectedDay < 0 ? "status-red" : "status-green"}`}>{amountSelectedDay != 0 ? (amountSelectedDay > 0) ? '+$' + Math.abs(amountSelectedDay).toFixed(2) : '-$' + Math.abs(amountSelectedDay).toFixed(2) : '$0'}</div>
                      </div>
                    </div>
                  </div>

                  <div className="row row-gap-3 mt-4">
                    <ScaleOverviewItem
                      src={balanceImg}
                      heading="Balance Overview"
                      min={"$" + minBalance != null ? minBalance : 0}
                      max={"$" + maxBalance != null ? maxBalance : 0}
                      percent={(minBalance > 0 && maxBalance > 0) ? 100 * (accountBalance - minBalance) / (maxBalance - minBalance) : 0}
                      current={"$" + accountBalance}
                    />

                    <ScaleOverviewItem
                      src={equityImg}
                      heading="Equity Overview"
                      min={"$" + minEquity != null ? minEquity : 0}
                      max={"$" + maxEquity != null ? maxEquity : 0}
                      percent={(minEquity > 0 && maxEquity > 0) ? 100 * (accountEquity - minEquity) / (maxEquity - minEquity) : 0}
                      current={"$" + accountEquity}
                    />
                  </div>

                </div>
              </div>
            </div>


            {/* --- Daily Summary section --- */}
            <div className="col-12">
              <div className="common-box">

                <div className="common-box-heading cbh-small">Daily Summary</div>

                <div className="table-responsive custom-table-responsive">
                  <table className="custom-table">
                 
    <thead>
    <tr>
        <th>Sr.
            {/* <div className="th-sort-icons">
                {sortField === 'close_date' && (sortDirection === 'asc' ? <Icons.ChevronUpSvg /> : <Icons.ChevronDownSvg />)}
            </div> */}
        </th>
        <th onClick={() => handleSort('close_date')}
          className={sortField === 'close_date' ? (sortDirection === 'asc' ? 'asc active' : 'desc active') : ''}>Date
            <div className="th-sort-icons">
              <Icons.ChevronUpSvg 
                className={sortField === 'close_date' && sortDirection === 'asc' ? 'asc' : ''} 
              />
              <Icons.ChevronDownSvg 
                className={sortField === 'close_date' && sortDirection === 'desc' ? 'desc' : ''} 
              />
            </div>
        </th>
        <th onClick={() => handleSort('total_trade')} className={sortField === 'total_trade' ? (sortDirection === 'asc' ? 'asc active' : 'desc active') : ''}>Trades
        <div className="th-sort-icons">
              <Icons.ChevronUpSvg 
                className={sortField === 'total_trade' && sortDirection === 'asc' ? 'asc' : ''} 
              />
              <Icons.ChevronDownSvg 
                className={sortField === 'total_trade' && sortDirection === 'desc' ? 'desc' : ''} 
              />
            </div>
        </th>
        <th onClick={() => handleSort('lots')} className={sortField === 'lots' ? (sortDirection === 'asc' ? 'asc active' : 'desc active') : ''}>Lots
            <div className="th-sort-icons">
            <Icons.ChevronUpSvg 
                className={sortField === 'lots' && sortDirection === 'asc' ? 'asc' : ''} 
              />
              <Icons.ChevronDownSvg 
                className={sortField === 'lots' && sortDirection === 'desc' ? 'desc' : ''} 
              />
            </div>
        </th>
        <th onClick={() => handleSort('profit')} className={sortField === 'profit' ? (sortDirection === 'asc' ? 'asc active' : 'desc active') : ''}>Result
            <div className="th-sort-icons">
            <Icons.ChevronUpSvg 
                className={sortField === 'profit' && sortDirection === 'asc' ? 'asc' : ''} 
              />
              <Icons.ChevronDownSvg 
                className={sortField === 'profit' && sortDirection === 'desc' ? 'desc' : ''} 
              />
            </div>
        </th>
    </tr>
</thead>


                    <tbody>
                      {(dailySummaryTradingList && dailySummaryTradingList.length > 0 && !isLoading) &&
                          dailySummaryTradingList.map((dailySummaryData, index) => (
                            <tr key={index}>
                              <td>{++index}</td>
                              <td>{anotherFormatDate(dailySummaryData.date_formatted)}</td>
                              <td>{dailySummaryData.total_trade}</td>
                              <td>{dailySummaryData.lots}</td>
                              <td>
                               {
                                  (dailySummaryData.profit - dailySummaryData.loss) > 0 ? (
                                    <span className="td-status status-green">+${(dailySummaryData.profit - dailySummaryData.loss).toFixed(2)}</span>
                                  ) : (dailySummaryData.profit - dailySummaryData.loss) < 0 ? (
                                    <span className="td-status status-red">-${(dailySummaryData.loss - dailySummaryData.profit).toFixed(2)}</span>
                                  ) : (
                                    <span>$0</span>
                                  )
                                }
                              </td>
                          </tr>
                          ))
                      }
                    </tbody>
                  </table>
                </div>

                <div className="empty-box">
                    {(dailySummaryTradingList.length <= 0) &&
                      <EmptyBox className="empty-div-bx" eh="Data is not found" esh="Try adjusting filters to get results." />
                    }
                </div>

                {(dailySummaryTradingList && dailySummaryTradingList.length > 0 && !isLoading) &&
                <Pagination
                  currentPage={pageDailySummary}
                  totalPages={totalPagesDailySummary}
                  onPageChange={onChangePageDailySummary}
                  prev={changePrevPageDailySummary}
                  next={changeNextPageDailySummary}
                />
                }

              </div>
            </div>


            {/* --- Open Positions section --- */}
            {/* <div className="col-12 d-none">
              <div className="common-box">
                <div className="common-box-heading cbh-small">Open Positions</div>

                <div className="table-responsive custom-table-responsive">
                  <table className="custom-table">
                    <thead>
                      <tr>
                        <th>Sr.<div className="th-sort-icons"><Icons.ChevronUpSvg /><Icons.ChevronDownSvg /></div></th>
                        <th>Trade ID<div className="th-sort-icons"><Icons.ChevronUpSvg /><Icons.ChevronDownSvg /></div></th>
                        <th>Symbol<div className="th-sort-icons"><Icons.ChevronUpSvg /><Icons.ChevronDownSvg /></div></th>
                        <th>Type<div className="th-sort-icons"><Icons.ChevronUpSvg /><Icons.ChevronDownSvg /></div></th>
                        <th>Entry Date<div className="th-sort-icons"><Icons.ChevronUpSvg /><Icons.ChevronDownSvg /></div></th>
                        <th>Entry<div className="th-sort-icons"><Icons.ChevronUpSvg /><Icons.ChevronDownSvg /></div></th>
                        <th>Tp<div className="th-sort-icons"><Icons.ChevronUpSvg /><Icons.ChevronDownSvg /></div></th>
                        <th>Sl<div className="th-sort-icons"><Icons.ChevronUpSvg /><Icons.ChevronDownSvg /></div></th>
                        <th>Lots<div className="th-sort-icons"><Icons.ChevronUpSvg /><Icons.ChevronDownSvg /></div></th>
                        <th>Swap<div className="th-sort-icons"><Icons.ChevronUpSvg /><Icons.ChevronDownSvg /></div></th>
                        <th>Profit<div className="th-sort-icons"><Icons.ChevronUpSvg /><Icons.ChevronDownSvg /></div></th>
                      </tr>
                    </thead>
                    {!isLoading && <tbody>
                      {(openTradingList && openTradingList.length > 0 && !isLoading) &&
                        openTradingList.map((openTrading, index) => (
                          <tr key={index}>
                            <td>{++index}</td>
                            <td className="status-white">{openTrading.trade_id}</td>
                            <td>{(openTrading.symbol) ? openTrading.symbol.name : '-'}</td>
                            <td>{openTrading.side == 'buy' ? (
                              <span className="td-status status-green2">Buy</span>
                            ) : (
                              <span className="td-status status-red2">Sell</span>
                            )}</td>
                            <td>{anotherFormatDate(openTrading.open_date)}</td>
                            <td>{openTrading.open_price}</td>
                            <td>{openTrading.take_profit != '' ? openTrading.take_profit : '-'}</td>
                            <td>{openTrading.stop_loss != '' ? openTrading.stop_loss : '-'}</td>
                            <td>{openTrading.lots}</td>
                            <td>{openTrading.swap}</td>
                            <td>
                              {<span className="td-status status-red">{openTrading.profit > 0 ? "+$" + openTrading.profit : "$" + 0}</span>}
                            </td>
                          </tr>
                        ))
                      }


                    </tbody>

                    }
                  </table>

                </div>
                <div className="empty-box">
                  {(openTradingList.length <= 0) &&
                    <EmptyBox className="empty-div-bx" eh="Data is not found" esh="Try adjusting filters to get results." />
                  }
                </div>

                <div className="pagenation-filter d-none">
                  <div className="pf-left">
                    <div className="pf-label">Go to</div>
                    <div className="pf-input">
                      <input type="number" placeholder="Page" value={goPageOpenTrading} onChange={onChangePageOpenTrading} />
                    </div>
                  </div>
                  <div className="pf-right">
                    <div className="pf-pagination">
                      <div className="pf-btn" onClick={changePrevPageOpenTrading}>Prev</div>
                      <div className="pf-text">
                        <div>{pageOpenTrading}</div>
                        <span>/</span>
                        <div>{totalPagesOpenTrading}</div>
                      </div>
                      <div className="pf-btn pb-right" onClick={changeNextPageOpenTrading}>Next</div>
                    </div>
                  </div>
                </div>

                {(openTradingList && openTradingList.length > 0 && !isLoading) &&
                  <Pagination
                    currentPage={pageOpenTrading}
                    totalPages={totalPagesOpenTrading}
                    onPageChange={onChangePageTradingHistory}
                    prev={changePrevPageOpenTrading}
                    next={changeNextPageOpenTrading}
                  />
                }

              </div>
            </div> */}





            {/* --- Trading History section start --- */}
            <div className="col-12">
              <div className="common-box">
                <div className="common-box-heading cbh-small">Trading History</div>

                <div className="table-responsive custom-table-responsive">
                  <table className="custom-table">
                  <thead>
                      <tr>
                        <th>Sr.<div className="th-sort-icons"><Icons.ChevronUpSvg /><Icons.ChevronDownSvg /></div></th>
                        <th>Trade ID<div className="th-sort-icons"><Icons.ChevronUpSvg /><Icons.ChevronDownSvg /></div></th>
                        <th>Symbol<div className="th-sort-icons"><Icons.ChevronUpSvg /><Icons.ChevronDownSvg /></div></th>
                        <th>Type<div className="th-sort-icons"><Icons.ChevronUpSvg /><Icons.ChevronDownSvg /></div></th>
                        <th>Open Date<div className="th-sort-icons"><Icons.ChevronUpSvg /><Icons.ChevronDownSvg /></div></th>
                        <th>Open<div className="th-sort-icons"><Icons.ChevronUpSvg /><Icons.ChevronDownSvg /></div></th>
                        <th>Closed Date<div className="th-sort-icons"><Icons.ChevronUpSvg /><Icons.ChevronDownSvg /></div></th>
                        <th>Close<div className="th-sort-icons"><Icons.ChevronUpSvg /><Icons.ChevronDownSvg /></div></th>
                        <th>Tp<div className="th-sort-icons"><Icons.ChevronUpSvg /><Icons.ChevronDownSvg /></div></th>
                        <th>Sl<div className="th-sort-icons"><Icons.ChevronUpSvg /><Icons.ChevronDownSvg /></div></th>
                        <th>Lots<div className="th-sort-icons"><Icons.ChevronUpSvg /><Icons.ChevronDownSvg /></div></th>
                        <th>Commission<div className="th-sort-icons"><Icons.ChevronUpSvg /><Icons.ChevronDownSvg /></div></th>
                        <th>Profit<div className="th-sort-icons"><Icons.ChevronUpSvg /><Icons.ChevronDownSvg /></div></th>
                      </tr>
                    </thead>
                    {!isLoading && <tbody>
                      {(tradingHistoryList && tradingHistoryList.length > 0 && !isLoading) &&
                        tradingHistoryList.map((tradingHistory, index) => (
                          <tr key={index}>
                            <td>{++index}</td>
                            <td className="status-white">{tradingHistory.trade_id}</td>
                            <td>{(tradingHistory.symbol) ? tradingHistory.symbol.name : '-'}</td>
                            <td>{tradingHistory.side == 'buy' ? (
                              <span className="td-status status-green2">Buy</span>
                            ) : (
                              <span className="td-status status-red2">Sell</span>
                            )}</td>
                            <td>{anotherFormatDate(tradingHistory.open_date)}</td>
                            <td>{tradingHistory.open_price}</td>
                            <td>{anotherFormatDate(tradingHistory.close_date)}</td>
                            <td>{tradingHistory.close_price}</td>
                            <td>{tradingHistory.take_profit != '' ? tradingHistory.take_profit : '-'}</td>
                            <td>{tradingHistory.stop_loss != '' ? tradingHistory.stop_loss : '-'}</td>
                            <td>{tradingHistory.lots}</td>
                            <td>{tradingHistory.commission != '' ? tradingHistory.commission : '-'}</td>
                            <td>
                              {
                                tradingHistory.profit > 0 ? (
                                  <span className="td-status status-green">${(tradingHistory.profit).toFixed(2)}</span>
                                ) : tradingHistory.loss > 0 ? (
                                  <span className="td-status status-red">-${(tradingHistory.loss).toFixed(2)}</span>
                                ) : (
                                  <span>$0</span>
                                )
                              }
                            </td>
                          </tr>
                        ))
                      }

                    </tbody>
                    }
                  </table>

                </div>
                <div className="empty-box">
                  {(tradingHistoryList.length <= 0) &&
                    <EmptyBox className="empty-div-bx" eh="Data is not found" esh="Try adjusting filters to get results." />
                  }
                </div>

                <div className="pagenation-filter d-none">
                  <div className="pf-left">
                    <div className="pf-label">Go to</div>
                    <div className="pf-input">
                      <input type="number" placeholder="Page" value={goPageTradingHistory} onChange={onChangePageTradingHistory} />
                    </div>
                  </div>
                  <div className="pf-right">
                    <div className="pf-pagination">
                      <div className="pf-btn" onClick={changePrevPageTradingHistory}>Prev</div>
                      <div className="pf-text">
                        <div>{pageTradingHistory}</div>
                        <span>/</span>
                        <div>{totalPagesTradingHistory}</div>
                      </div>
                      <div className="pf-btn pb-right" onClick={changeNextPageTradingHistory}>Next</div>
                    </div>
                  </div>
                </div>

                {(tradingHistoryList && tradingHistoryList.length > 0 && !isLoading) &&
                  <Pagination
                    currentPage={pageTradingHistory}
                    totalPages={totalPagesTradingHistory}
                    onPageChange={onChangePageTradingHistory}
                    prev={changePrevPageTradingHistory}
                    next={changeNextPageTradingHistory}
                  />
                }

              </div>
            </div>


            {/* --- Disclaimer alert message --- */}
            <div className="col-12">
              <Alert
                className="status-red2"
                heading="Disclaimer"
                text="Trading results on this dashboard are informative only. Real-time trading updates can be monitored through the trading platform. The above account statistics are automatically updated periodically when you have active trade(s). In case of any discrepancies, please be patient for the data to sync, if the issue still persists please contact us through help center."
              />
            </div>

          </div>
        </div>
      }

      {/* --- Credentials Modal --- */}
      <Modal className="zoom custom-content" centered show={isCredentialsModal} onHide={handleCredentialsModalClose}>
        <div className="custom-modal-header">
          <div className="cmh-lable">Account Credentials</div>
          <div className="cmh-sub-lable">#{accountNumber}</div>

          <CloseIcon onClick={handleCredentialsModalClose} />
        </div>

        <div className="custom-modal-body">
          <div className="challenge-credentials-bx">
            <div className={"credential-item " + email != '' ? 'd-none' : ''}>
              <div className="credential-label">Email</div>
              <div className="credential-data">{email}<span className="credential-icon" onClick={() => copyClick(email)}><Icons.CopySvg /></span></div>
            </div>
            <div className="credential-item">
              <div className="credential-label">Username</div>
              <div className="credential-data">{accountNumber}<span className="credential-icon" onClick={() => copyClick(accountNumber)}><Icons.CopySvg /></span></div>
            </div>
            <div className="credential-item">
              <div className="credential-label">Password</div>
              <div className="credential-data">{masterPassword}<span className="credential-icon" onClick={() => copyClick(masterPassword)}><Icons.CopySvg /></span></div>
            </div>
            <div className="credential-item">
              <div className="credential-label">Server</div>
              <div className="credential-data">Winprofx-Live<span className="credential-icon" onClick={() => copyClick("Winprofx-Live")}><Icons.CopySvg /></span></div>
            </div>
            <div className="credential-item">
              <div className="credential-label">Platform</div>
              <div className="credential-data">Metatrader 5 <span className="credential-icon" onClick={() => copyClick("Metatrader 5")}><Icons.CopySvg /></span></div>
            </div>
          </div>
          <div className="mt-3 mb-4">
            <div className="common-btn cb-eg3-fill cwf-am-hover mx-auto"><span>Open META TRADER 5</span></div>
          </div>
        </div>
      </Modal>

      {/* --- Analysis Modal --- */}
      <Modal className="zoom custom-content" centered show={isAnalysisModal} onHide={handleAnalysisModalClose}>
        <div className="custom-modal-header">
          <div className="cmh-lable">{snapShotTitle}</div>

          <CloseIcon onClick={handleAnalysisModalClose} />
        </div>

        <div className="custom-modal-body">
          <div className="common-data-bx">

            <div className="cdb-item">
              <div className="cdb-label">Number of days</div>
              <div className="cdb-data">{numberOfDays}</div>
            </div>

            <div className="cdb-item">
              <div className="cdb-label">Total trades taken</div>
              <div className="cdb-data">{totalTradesTaken > 0 ? totalTradesTaken.toFixed(2) : 0}</div>
            </div>

            <div className="cdb-item">
              <div className="cdb-label">Average trades per day</div>
              <div className="cdb-data">{avgTradesPerDay}</div>
            </div>

            <div className="cdb-item">
              <div className="cdb-label">Total lots used</div>
              <div className="cdb-data">{totalLotsUsed > 0 ? totalLotsUsed.toFixed(2) : 0}</div>
            </div>

            <div className="cdb-item">
              <div className="cdb-label">Average lots used</div>
              <div className="cdb-data">{avgLotsUsed > 0 ? avgLotsUsed.toFixed(2) : 0}</div>
            </div>

            <div className="cdb-item">
              <div className="cdb-label">Biggest win</div>
              <div className="cdb-data status-green">{biggestWin > 0 ? "$" + (biggestWin.toFixed(2)) : 0}</div>
            </div>

            <div className="cdb-item">
              <div className="cdb-label">Biggest loss</div>
              <div className="cdb-data status-red">{biggestLoss > 0 ? "$" + (biggestLoss.toFixed(2)) : 0}</div>
            </div>

            <div className="cdb-item">
              <div className="cdb-label">Positive days</div>
              <div className="cdb-data">{positiveDay}</div>
            </div>
            <div className="cdb-item">
              <div className="cdb-label">Negative days</div>
              <div className="cdb-data">{negativeDay}</div>
            </div>

          </div>
        </div>
      </Modal>

      {/* --- Snapshot Modal --- */}
      <Modal className="zoom custom-content" centered show={isSnapshotModal} onHide={handleSnapshotModalClose}>
        <div className="custom-modal-header">
          <div className="cmh-lable">Loss</div>

          <CloseIcon onClick={handleSnapshotModalClose} />
        </div>

        <div className="custom-modal-body">
          <div className="common-data-bx">

            <div className="cdb-item">
              <div className="cdb-label">Number of days</div>
              <div className="cdb-data">12</div>
            </div>

            <div className="cdb-item">
              <div className="cdb-label">Total trades taken</div>
              <div className="cdb-data">15</div>
            </div>

            <div className="cdb-item">
              <div className="cdb-label">Average trades per day</div>
              <div className="cdb-data">8.5</div>
            </div>

            <div className="cdb-item">
              <div className="cdb-label">Total lots used</div>
              <div className="cdb-data">0.006</div>
            </div>

            <div className="cdb-item">
              <div className="cdb-label">Average lots used</div>
              <div className="cdb-data">0.08</div>
            </div>

            <div className="cdb-item">
              <div className="cdb-label">Biggest win</div>
              <div className="cdb-data status-green">$3.32</div>
            </div>

            <div className="cdb-item">
              <div className="cdb-label">Biggest loss</div>
              <div className="cdb-data status-red">-$0.7</div>
            </div>

            <div className="cdb-item">
              <div className="cdb-label">Positive days</div>
              <div className="cdb-data">2</div>
            </div>

            {/* <div className="cdb-item">
                  <div className="cdb-label">Avg positive day</div>
              <div className="cdb-data status-green">${}</div>
              </div> */}

            <div className="cdb-item">
              <div className="cdb-label">Negative days</div>
              <div className="cdb-data">5</div>
            </div>

            {/* <div className="cdb-item">
                  <div className="cdb-label">Avg negative day</div>
                  <div className="cdb-data status-red">0</div>
              </div> */}

            {/* <div className="cdb-item">
                  <div className="cdb-label">Highest recorded equity</div>
                  <div className="cdb-data">$26,257.25</div>
              </div> */}

          </div>
        </div>
      </Modal>

      <FlashMessage type="success" isVisible={requestSubmitSuccess} message={requestSubmitSuccessMsg} />
      <FlashMessage type="error" isVisible={requestSubmitError} message={requestSubmitErrorMsg} />
    </>
  )
}
