import React from 'react'
import { Link } from 'react-router-dom';

// Icon Files
import Icons from './icons';

export default function Breadcrumb(props) {


    const IconComponent = Icons[props.breadcrumbIcon];

    return (
        <div className={`breadcrumb-main-bx ${props.className}`}>
            <div className="breadcrumb-heading-bx">
                <IconComponent />{props.breadcrumbHeading}
            </div>
            <div className="breadcrumb-bx">
                <Link className="breadcrumb-link" to="/home"><Icons.HomeSvg /></Link>
                {props.middleHeading && (
                    props.onClick ? (
                        <>
                            <div className='breadcrumb-sapretor'><Icons.RightSvg /></div>
                            <span className="breadcrumb-link" onClick={props.onClick}>{props.middleHeading}</span>
                        </>
                    ) : (
                        <>
                            <div className='breadcrumb-sapretor'><Icons.RightSvg /></div>
                            <Link className="breadcrumb-link" to={props.middleLink}>{props.middleHeading}</Link>
                        </>
                    )
                )}
                <div className='breadcrumb-sapretor'><Icons.RightSvg /></div>
                <div className="breadcrumb-link breadcrumb-active">{props.breadcrumbHeading}</div>
            </div>
        </div>
    )
}
