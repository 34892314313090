import * as React from 'react';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import Icons from '../../components/icons';

import { ThemeMode } from '../../context/ThemeMode';

const ArcGauge = ({ value, otherValue, color = '#52b202', color2="#3C394B", label, check=false }) => {
    const settings = { value, animate: true };

    // --- ---
    const { isDarkMode } = ThemeMode();
    // --- ---
    
    return (
        <div className="arc-item common-pe-none">
            <div className='arc-gauge-bx'>
            <Gauge
                {...settings}
                cornerRadius="50%"
                sx={() => ({
                    [`& .${gaugeClasses.valueText}`]: {
                        visibility: 'hidden',
                    },
                    [`& .${gaugeClasses.valueArc}`]: {
                        fill: color,
                    },
                    [`& .${gaugeClasses.referenceArc}`]: {
                        fill: `${isDarkMode ? color2 : "#0000000f"  }`,
                    },
                })}
            />
            {check && <div className="arc-check"><Icons.Check2Svg/></div>}
            <div className='agb-center'>
                <div className="arc-value">{value}%</div>
                {otherValue && <div className="arc-other-value">{otherValue} </div>}
            </div>
            </div>
            {label && <div className="arc-name">{label}</div>}
        </div>
    );
};

export default ArcGauge;
