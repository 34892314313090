import React from 'react'

export default function BestTodayItem({avatar, rank, badge, name, profit, balance }) {
  return (
    <>
    <div className={"best-today-item"}>
      <img className="bti-avatar" src={avatar} alt="" />
      <div className="bti-name">{name}</div>
      <div className="bti-inner">
        <img className="bti-badge-img" src={badge} alt="" />
        <div className='bti-type'>XAUUSD</div>
        <img className='bti-rank-img' src={rank} alt="" />
        <div className='bti-profit'>{profit}</div>
        <div className='bti-balance'>{balance}</div>
      </div>
    </div>
    </>
  )
}
