import React, { useState, useRef, useEffect } from 'react'
import * as Img from '../../components/Img';
import Icons from '../../components/icons'


import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth, getLoggedInUserData } from '../../context/AuthContext';
import FlashMessage from '../../components/FlashMessage';
import Button from 'react-bootstrap/Button';
import EmptyBox from '../../components/EmptyBox';
import Loaders from '../../components/Loader';
import Pagination from '../../components/Pagination';


export default function PayoutHistory() {

  //Auth related var

  const { apiCall } = useApi();
  const navigate = useNavigate();
  const { state, dispatch } = useAuth();
  const token = state.token || '';
  const isInitialMount = useRef(true);

  const { id } = useParams();

  //Loader
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [requestSubmitError, setRequestSubmitError] = useState(false);
  const [requestSubmitErrorMsg, setRequestSubmitErrorMsg] = useState(false);
  const [requestSubmitSuccess, setRequestSubmitSuccsess] = useState(false);
  const [requestSubmitSuccessMsg, setRequestSubmitSuccessMsg] = useState(false);

  const [payoutsData, setPayoutsData] = useState([]);
  const [pagePayoutsData, setPagePayoutsData] = useState(1);
  const [goPagePayoutsData, setGoPagePayoutsData] = useState(null);
  const [totalPagesPayoutsData, setTotalPagesPayoutsData] = useState(0);

  // const onChangePagePayoutsData = (pagePayoutsData) => {
  //   if (pagePayoutsData.target.value == '') {
  //     pagePayoutsData.target.value = 1;
  //   }
  //   if (pagePayoutsData.target.value < parseInt(totalPagesPayoutsData)) {
  //     setPagePayoutsData(pagePayoutsData.target.value);
  //     setGoPagePayoutsData(pagePayoutsData.target.value);
  //   } else {
  //     setPagePayoutsData(totalPagesPayoutsData);
  //     setGoPagePayoutsData(totalPagesPayoutsData);
  //   }
  // }

  const onChangePagePayoutsData = (pagePayoutsData) => {
    setPagePayoutsData(pagePayoutsData)
  }

  const changePrevPagePayoutsData = () => {
    if (pagePayoutsData > 1) {
      setPagePayoutsData(pagePayoutsData - 1);
      onChangePagePayoutsData(pagePayoutsData - 1)
    }

  }
  const changeNextPagePayoutsData = () => {
    console.log(pagePayoutsData);
    console.log(totalPagesPayoutsData);
    if (pagePayoutsData < totalPagesPayoutsData) {
      setPagePayoutsData(pagePayoutsData + 1);
      onChangePagePayoutsData(pagePayoutsData + 1)
    }
  }

  useEffect(() => {
    if (parseInt(pagePayoutsData) > 0) {
      getPayoutsData();
    }
  }, [pagePayoutsData]);

  useEffect(() => {
    const fetchData = async () => {
      const loggedInUser = getLoggedInUserData();
      const isUserLoggedIn = !!loggedInUser;

      if (isUserLoggedIn) {
        if (loggedInUser.id && token) {
          try {
            // getIbRequestStatus();
            const profitChart = await getPayoutsData();
            setDataLoaded(true);
            // console.log(tradeHistory);
            // Assuming getUserWalletGroupWise returns the data needed for wallets and walletGroups
          } catch (error) {
            console.error('Failed to fetch ib data:', error);
          }
        }
      }
    };

    if (isInitialMount.current) {
      isInitialMount.current = false;
      fetchData();
    }
  }, [token]);

  const getPayoutsData = async () => {
    try {
      setIsLoading(true);
      // console.log({id});
      const response = await apiCall(API_URL + 'get-payouts', {
        user_id: state.userData.id,
        token: token,
        page: pagePayoutsData,
        per_page: 5,
      });
      if (response.data.success == '0') {
        console.log(response.data.data);
        setPayoutsData(response.data.data.data);
        //   setpayoutsData(response.data.data.data)
        setTotalPagesPayoutsData(response.data.data.last_page)
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const anotherFormatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    };

    return date.toLocaleString('en-US', options);
  };

  function getStatusColor(paymentStatus) {
    switch (paymentStatus) {
      case 'pending':
        return 'status-yellow2';
      case 'approved':
        return 'status-green2';
      default:
        return 'status-red2';
    }
  }

  function getStatus(paymentStatus) {
    switch (paymentStatus) {
      case 'approved':
        return '';
      default:
        return 'd-none';
    }
  }

  const downloadInvoice = async (payoutID) => {
    try {
      // setIsLoading(true);
      // console.log({id});
      const response = await apiCall(API_URL + 'invoice-generate-and-download', {
        user_id: state.userData.id,
        token: token,
        payout_id: payoutID
      });
      if (response.data.success == '0') {
        // window.open(response.data.data.download_link);

        //   console.log(response);
        const link = document.createElement('a');
        link.href = response.data.data.download_link;
        link.setAttribute('target', '_blank'); // Open in a new tab

        // link.setAttribute('download', 'passed_certificate.pdf'); // Set default filename (optional)
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

      }
      // setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const downloadCertificate = async (payoutID) => {
    try {
      // setIsLoading(true);
      // console.log({id});
      const response = await apiCall(API_URL + 'download-pdf', {
        user_id: state.userData.id,
        token: token,
        type: "payout",
        payout_id: payoutID
      });
      if (response.data.success == '0') {
        // window.open(response.data.data.download_link);

        //   console.log(response);
        const link = document.createElement('a');
        link.href = response.data.data.download_link;
        link.setAttribute('target', '_blank'); // Open in a new tab

        // link.setAttribute('download', 'passed_certificate.pdf'); // Set default filename (optional)
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

      }
      // setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  return (
    <div className="col-12">
      {!dataLoaded && <Loaders />}
      <div className="common-box">
        <div className="common-box-heading">Payout History</div>
        <div className="table-responsive custom-table-responsive">
          <table className="custom-table">
            <thead>
              <tr>
                <th>Sr.<div className="th-sort-icons"><Icons.ChevronUpSvg /><Icons.ChevronDownSvg /></div></th>
                <th>Reference Id<div className="th-sort-icons"><Icons.ChevronUpSvg /><Icons.ChevronDownSvg /></div></th>
                <th>Payout Type<div className="th-sort-icons"><Icons.ChevronUpSvg /><Icons.ChevronDownSvg /></div></th>
                <th>Payment Method<div className="th-sort-icons"><Icons.ChevronUpSvg /><Icons.ChevronDownSvg /></div></th>
                <th>Requested On<div className="th-sort-icons"><Icons.ChevronUpSvg /><Icons.ChevronDownSvg /></div></th>
                <th>Payment Status<div className="th-sort-icons"><Icons.ChevronUpSvg /><Icons.ChevronDownSvg /></div></th>
                <th>Amount<div className="th-sort-icons"><Icons.ChevronUpSvg /><Icons.ChevronDownSvg /></div></th>
                <th>Admin Note<div className="th-sort-icons"><Icons.ChevronUpSvg /><Icons.ChevronDownSvg /></div></th>
                <th>Certificate<div className="th-sort-icons"><Icons.ChevronUpSvg /><Icons.ChevronDownSvg /></div></th>
                <th>Invoice<div className="th-sort-icons"><Icons.ChevronUpSvg /><Icons.ChevronDownSvg /></div></th>
              </tr>
            </thead>
            {!isLoading && <tbody>
              {(payoutsData && payoutsData.length > 0 && !isLoading) &&
                payoutsData.map((payoutdata, index) => (
                  <tr key={index}>
                    <td>{++index}</td>
                    <td className="status-white">{"#" + payoutdata.reference_id}</td>
                    <td>{payoutdata.payout_type != null ? payoutdata.payout_type : 'trading account'}</td>
                    <td><div className="td-img"><img className='d-none' src={Img.UPI} alt=""/>{payoutdata.payment_method.name}</div></td>
                    <td>{anotherFormatDate(payoutdata.created_at)}</td>
                    <td><span className={"td-status td-dot ts-payment " + getStatusColor(payoutdata.payment_status)}>{payoutdata.payment_status}</span></td>
                    <td>${payoutdata.amount}</td>
                    <td className="td-wrap"><div className="td-admin-note">-</div></td>
                    <td><div className={"common-btn cb-eg3-fill cwf-am-hover cb-small " + getStatus(payoutdata.payment_status)} onClick={() => downloadCertificate(payoutdata.id)}><span><Icons.AcademicCapSvg />Download</span></div></td>
                    <td><div className={"common-btn cb-eg3-fill cwf-am-hover cb-small " + getStatus(payoutdata.payment_status)} onClick={() => downloadInvoice(payoutdata.id)}><span><Icons.ArrowDownTraySvg />Download</span></div></td>

                  </tr>
                ))
              }
            </tbody>
            }

          </table>
        </div>

        <div className="empty-box">
          {(payoutsData.length <= 0) &&
            <EmptyBox className="empty-div-bx" eh="Data is not found" esh="Try adjusting filters to get results." />
          }
        </div>

        {(payoutsData && payoutsData.length > 0 && !isLoading) &&
        <Pagination
          currentPage={pagePayoutsData}
          totalPages={totalPagesPayoutsData}
          onPageChange={onChangePagePayoutsData}
          prev={changePrevPagePayoutsData}
          next={changeNextPagePayoutsData}
        />
        }


        {/* <div className="pagenation-filter">
          <div className="pf-left">
            <div className="pf-label">Go to</div>
            <div className="pf-input">
              <input type="number" placeholder="Page" value={goPagePayoutsData} onChange={onChangePagePayoutsData} />
            </div>
          </div>
          <div className="pf-right">
            <div className="pf-pagination">
              <div className="pf-btn" onClick={changePrevPagePayoutsData}>Prev</div>
              <div className="pf-text">
                <div>{pagePayoutsData}</div>
                <span>/</span>
                <div>{totalPagesPayoutsData}</div>
              </div>
              <div className="pf-btn pb-right" onClick={changeNextPagePayoutsData}>Next</div>
            </div>
          </div>
        </div> */}

      </div>
    </div>
  )
}
