import React, { useState, useRef, useEffect } from "react";
import { Link, NavLink, useNavigate,useParams } from 'react-router-dom';
import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';
import { useAuth,getLoggedInUserData } from '../../context/AuthContext';

// Context Files
import { useMenu } from '../../context/MenuContext';

// 
import * as Img from '../../components/Img';
import Icons from '../../components/icons'
import Logo from "../../components/Logo";
import CloseIcon from "../../components/CloseIcon";

function UserInfoBx() {
    return(
        <Link className="smb-f-user">
            <div className="sfu-img">
                <img src={Img.icon} alt="" />
            </div>
            <div className="sfu-data">
                <div className="sfu-name">Koray Okumus</div>
                <div className="sfu-other">okumus@gmail.com</div>
            </div>
        </Link>
    )
}


export default function Sidemenu() {
    // const { dispatch,state } = useAuth();
    const { apiCall } = useApi();
    // const navigate = useNavigate();
    const { state, dispatch } = useAuth();
    const token = state.token || '';
    const isInitialMount = useRef(true);

    const { id } = useParams();
    // --- ---
    const { isActive, toggleMenu, closeMenu, isMinActive } = useMenu();
    const navigate = useNavigate();
    // --- ---
    const [isLoading, setIsLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
    const handleLogout = () => {
        dispatch({ type: 'LOGOUT'});
        navigate('/');
    }
    useEffect(() => {
        const fetchData = async () => {
          const loggedInUser = getLoggedInUserData();
          const isUserLoggedIn = !!loggedInUser;
          
          if (isUserLoggedIn) {
            if (loggedInUser.id && token) {
              try {
                // getIbRequestStatus();
                // const affiliateCheck = await checkAffiliateAvailable();
                setDataLoaded(true);
                // console.log(tradeHistory);
                // Assuming getUserWalletGroupWise returns the data needed for wallets and walletGroups
              } catch (error) {
                navigate('/login');
                console.error('Failed to fetch ib data:', error);
              }
            }
          }
        };
  
        if (isInitialMount.current) {
          isInitialMount.current = false;
          fetchData();
        }
    }, [token]);
    // const [isAffiliate, setAffiliate] = useState(0);
    //  const checkAffiliateAvailable = async () => {
    //     try {
    //       // setIsLoading(true);
    //       // console.log({id});
    //       const response = await apiCall(API_URL +'check-affiliate-available', {
    //           user_id: state.userData.id,
    //           token: token
    //       });
    //       if (response.data.success == '0') {
    //         //   console.log(response.data.data);
    //           setAffiliate(response.data.data);
    //         //   setTotalPagesDailySummary(response.data.data.last_page)
    //       }
    //       // setIsLoading(false);
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //     }
    //   };
  return (
    <>
        {/* Aside Menu Start */}
        <aside className={`side-menu-bx ${isMinActive ? 'smb-mini' : ''}  ${isActive ? 'active' : ''}`} onClick={closeMenu}>

            <div className="smb-header">
                <Logo to="/home" className="smb-logo" />
            </div>

            {/* <div className="smb-min-btn" onClick={toggleMinMenu}>
                <Icons.LeftSvg />
            </div> */}

            <div className="smb-menu-bx">
                {/* <UserInfoBx /> */}
                             
                <NavLink to="/new-challenge" className="smb-link highlight-link">
                    <div className="smb-icon"><Icons.PlusSvg /></div><div className="smb-text">New Challenge</div>
                </NavLink>

                <div className="smb-link-separator"></div>

                <div className="smb-label" >Main</div>
                <NavLink to="/home" className="smb-link">
                    <div className="smb-icon"><Icons.HomeSvg /></div><div className="smb-text">Home</div>
                </NavLink>
                <NavLink to="/challenge-accounts" className="smb-link">
                    <div className="smb-icon"><Icons.UsersSvg /></div><div className="smb-text">Accounts</div>
                </NavLink>
                <NavLink to="/payouts" className="smb-link">
                    <div className="smb-icon"><Icons.EwalletsSvg /></div><div className="smb-text">Payouts</div>
                </NavLink>
                <NavLink to="/leaderboard" className="smb-link">
                    <div className="smb-icon"><Icons.FlagSvg /></div><div className="smb-text">Leaderboard</div>
                </NavLink>
                <NavLink to="/calendar" className="smb-link">
                    <div className="smb-icon"><Icons.CalendarSvg /></div><div className="smb-text">Calendar</div>
                </NavLink>
                
                <div className="smb-link-separator"></div>

                <div className="smb-label">Other</div>
                {/* {isAffiliate == 1 && ( */}
                    <NavLink to="/affiliate" className="smb-link">
                        <div className="smb-icon"><Icons.UsersSvg /></div><div className="smb-text">Affiliate</div>
                    </NavLink>
                {/* )} */}
                <NavLink to="/settings" className="smb-link">
                    <div className="smb-icon"><Icons.Cog6Svg /></div><div className="smb-text">Settings</div>
                </NavLink>
                <Link className="smb-link" onClick={handleLogout}>
                    <div className="smb-icon"><Icons.LogoutSvg /></div><div className="smb-text">Logout</div>
                </Link>

                <div className="py-3"></div>
            
            </div>

            {/* <div className="smb-footer" >
                <UserInfoBx />
            </div> */}
        </aside>
        <div onClick={toggleMenu} className={`side-menu-overlay ${isActive ? 'active' : ''}`}>
            <CloseIcon className="status-white" />
        </div>
        {/* Aside Menu Start */}
    </>
  )
}
