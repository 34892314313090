import React from 'react';

export default function ChallengeRequestCard({ col, name, step, limit, isRealAccount=false }) {
  return (
    <div className={col}>
      <div className={`ccb-request-info ${isRealAccount ? "ccb-real-ac" : "ccb-other-ac" }`}>
          {isRealAccount ? `Your real account request for ${step} account ${name}  is under review` : `Your request for challenge ${name} ${step} for account size $${limit} is under review`}
      </div>
    </div>
  );
}
