import React from 'react'

export default function Input({solClass="", heading, className="", type, sgl, placeholder, value, onChange, error, errorMeg, readOnly = false}) {
    
    const handleChange = (event) => {
        onChange(event.target.value);
    };
    return (
        <>
            {(heading && <div className={`sgr-outer-label ${solClass}`}>{heading}</div> )}
            <div className={`signinup-group ${className} ${error ? "error-group" : ""}`}>
                <input type={type} placeholder={placeholder} value={value} onChange={handleChange} readOnly={readOnly}/>
                {(sgl && <div className='group_left_icon'>{sgl}</div> )}
                {(error && errorMeg && <span className="form-error-msg">{errorMeg}</span>)}
            </div>
        </>
    )
}
